<template>
    <userTemplate :pattern="2">
        <el-row>
            <el-col :span="20" :offset="2">
                <p>Room ID</p>
                <el-input placeholder="Please input" v-model="input"></el-input>
                <el-button @click="connect()" class="w-100 mg-y-5" type="primary">เข้าร่วม</el-button>
            </el-col>
        </el-row>
    </userTemplate>
</template>
<script>
import userTemplate from '@/template/VoteUserTemplate'
export default {
    components:{
        userTemplate
    },
    data(){
        return{
            name
        }
    },
    methods:{
        connect(){

        }
    }
}
</script>